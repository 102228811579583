"use client";

import { RecoilRoot } from "recoil";
import { RecoilDevTools } from "recoil-gear";

import type { Metadata } from "next";

import { PHProvider } from "./providers";
import dynamic from "next/dynamic";

import ToastContainerWrapper from "./shared/provider/ToastProvider";
import PsiTheme from "./shared/Theme";
import "./globals.css";
import { useRouter } from "next/navigation";

const PostHogPageView = dynamic(() => import("./PostHogPageView"), {
  ssr: false,
});

const metadata: Metadata = {
  title: "Psi do Futuro",
  description: "Psi do Futuro - Plataforma de agendamento de consultas online",
};

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  if (typeof window !== "undefined" && window.location.href == "/") {
    router.push("/auth/login");
  }
  return (
    <html lang="pt-br" className="h-full">
      <link rel="icon" href="/favicon.ico" sizes="any" />
      <RecoilRoot>
        <RecoilDevTools key="Psi do Futuro" />
        <PsiTheme>
          <PHProvider>
            <body className={`h-full bg-white`}>
              <PostHogPageView />

              {children}
              <ToastContainerWrapper />
            </body>
          </PHProvider>
        </PsiTheme>
      </RecoilRoot>
    </html>
  );
}
