// app/providers.tsx
"use client";
import posthog, { CapturedNetworkRequest } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: true,
    disable_session_recording: false,
    persistence: "localStorage",
    autocapture: true,
    cookie_name: "posthog",
    session_recording: {
      maskCapturedNetworkRequestFn: (request: CapturedNetworkRequest) => {

          // ... or remove the query string from the URL
          (request.name as unknown) = request.name.split('?')[0]

          // ...redact the request or response body **however makes sense for your payloads**
          request.requestBody = request.requestBody?.replace(/"password":\s*"[^"]*"/g, '"password": "redacted-password"')
          request.responseBody = request.responseBody?.replace(/"password":\s*"[^"]*"/g, '"password": "redacted-password"')

          // finally return the request
          return request
      }
    }
  });
}

export function PHProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
