import type { CustomFlowbiteTheme } from "flowbite-react";
import { Flowbite } from "flowbite-react";
import { ReactNode } from "react";

const customTheme: CustomFlowbiteTheme = {
  spinner: {
    base: "inline animate-spin text-gray-200",
    color: {
      failure: "fill-red-600",
      gray: "fill-gray-600",
      info: "fill-primary-500",
      pink: "fill-pink-600",
      purple: "fill-purple-600",
      success: "fill-green-500",
      warning: "fill-yellow-400",
    },
    light: {
      off: {
        base: "dark:text-gray-600",
        color: {
          failure: "",
          gray: "dark:fill-gray-300",
          info: "",
          pink: "",
          purple: "",
          success: "",
          warning: "",
        },
      },
      on: {
        base: "",
        color: {
          failure: "",
          gray: "",
          info: "",
          pink: "",
          purple: "",
          success: "",
          warning: "",
        },
      },
    },
    size: {
      xs: "h-3 w-3",
      sm: "h-4 w-4",
      md: "h-6 w-6",
      lg: "h-8 w-8",
      xl: "h-10 w-10",
    },
  },
  accordion: {
    root: {
      base: "divide-y divide-gray-200 border-gray-200 ",
      flush: {
        off: "rounded-b-3xl",
        on: "border-b",
      },
    },
    content: {
      base: "px-5 bg-white rounded-b-4xl",
    },
    title: {
      arrow: {
        base: "h-6 w-6 shrink-0",
        open: {
          off: "",
          on: "rotate-180",
        },
      },
      base: "flex w-full  items-center justify-between bg-white py-5 px-5 text-left font-medium",
      flush: {
        off: "hover:text-gray-600 focus:ring-none dark:focus:ring-none bg-gray-50 text-gray-500 rounded-b-4xl ",
        on: "bg-transparent dark:bg-transparent",
      },
      heading: "",
      open: {
        off: "",
        on: "text-gray-500 bg-gray-50 rounded-b-none rounded-none  ",
      },
    },
  },
  avatar: {
    root: {
      img: {
        base: "rounded",
        off: "relative overflow-hidden bg-gray-100 dark:bg-gray-600",
        on: "",
        placeholder: "absolute w-auto h-auto text-gray-400 -bottom-1",
      },
      size: {
        xs: "w-[40px] h-[40px]",
        lg: "w-[72px] h-[72px]",
      },
    },
  },
  checkbox: {
    root: {
      base: "h-4 w-4 rounded focus:ring-2 border border-gray-300 dark:border-primary-600 dark:bg-primary-500 bg-gray-100  focus:ring-primary-600 dark:ring-offset-primary-800 dark:focus:ring-primary-600",
      color: {
        default: "text-primary-500",
      },
    },
  },
  progress: {
    base: "w-full overflow-hidden bg-transparent dark:bg-gray-700",
    bar: " text-center font-medium leading-none text-cyan-300 dark:text-cyan-100 space-x-2",
    color: {
      dark: "bg-gray-600 dark:bg-gray-300",
      blue: "bg-blue-500",
    },
  },
  navbar: {
    root: {
      base: "bg-white px-6 py-2 fixed max-h-navbar w-full z-20 top-0 start-0 border-b-2 border-surface-600",
    },
    toggle: {
      base: "sm:hidden",
    },

    collapse: {
      list: "sm:hidden mt-4 absolute mt-2  md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium bg-red-400",
      hidden: {
        on: "hidden",
        off: "",
      },
    },
    link: {
      base: "sm:hidden",
    },
  },
  dropdown: {
    content: "text-gray-800",
  },
  textInput: {
    base: "w-full",

    field: {
      input: {
        base: "w-full py-[10px] px-[14px] text-base",
        sizes: {
          md: "h-[50px] font-inter",
        },
        colors: {
          gray: "text-gray-800 font-medium bg-surface-100 border-0 focus:border-2 placeholder:text-gray-300 focus:border-surface-200 focus:ring-0 focus:outline-none focus-visible:outline-none",
          failure:
            "bg-surface-100 border-2 border-red-500 text-red-600 font-medium placeholder:text-red-500 text-base focus:border-2 focus:ring-0 focus:outline-none  focus:border-red-500 focus:outline-none focus-visible:outline-none outline-none",
        },
      },
    },
  },
  modal: {
    root: {
      base: "fixed top-0 right-0 left-0 z-50  overflow-y-auto overflow-x-hidden md:inset-0",
      show: {
        on: "flex bg-primary-50 bg-opacity-5 backdrop-blur ",
        off: "hidden",
      },
      sizes: {
        sm: "max-w-sm",
        md: "max-w-md",
        lg: "max-w-lg",
        xl: "max-w-xl",
        "2xl": "max-w-2xl",
        "3xl": "max-w-3xl",
        "4xl": "max-w-4xl",
        "5xl": "max-w-5xl",
        "6xl": "max-w-6xl",
        "7xl": "max-w-7xl",
      },
      positions: {
        "top-left": "items-start justify-start",
        "top-center": "items-start justify-center",
        "top-right": "items-start justify-end",
        "center-left": "items-center justify-start",
        center: "items-center justify-center",
        "center-right": "items-center justify-end",
        "bottom-right": "items-end justify-end",
        "bottom-center": "items-end justify-center",
        "bottom-left": "items-end justify-start",
      },
    },
    content: {
      base: "relative w-full p-4 md:h-auto",
      inner: "relative rounded-lg bg-white shadow  flex flex-col ",
    },
    body: {
      base: "p-6 flex-1 overflow-auto",
      popup: "pt-0",
    },
    header: {
      base: "flex items-start justify-between rounded-t border-b p-5",
      popup: "p-2 border-b-0",
      title: "text-xl font-medium text-gray-900 ",
      close: {
        base: "ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-2xl text-gray-400 hover:bg-primary-50 hover:text-primary-500 ",
        icon: "h-5 w-5",
      },
    },
    footer: {
      base: "flex items-center space-x-2 rounded-b border-gray-200 p-6",
      popup: "border-t",
    },
  },
};

export default function PsiTheme({ children }: { children: ReactNode }) {
  return <Flowbite theme={{ theme: customTheme }}>{children}</Flowbite>;
}
