"use client";

import "react-toastify/dist/ReactToastify.css";
import { IoClose } from "react-icons/io5";
import { ToastContainer } from "react-toastify";

export default function ToastContainerWrapper() {
  return (
    <ToastContainer
      autoClose={false}
      limit={2}
      theme="colored"
      className="lg:w-toast w-full p-0 m-0 before:p-0 before:m-0 after:p-0 after:m-0"
      closeButton={({ closeToast }) => (
        <button onClick={closeToast} className="text-2xl">
          <IoClose />
        </button>
      )}
    />
  );
}
